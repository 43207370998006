import Icon from '@/components/AnalyticsCardIcon';

export default {
  name: 'ReactionsChartsCard',

  components: {
    CircularProgress: () => import('@/components/CircularProgress'),
    Icon,
  },

  props: {
    index: {
      required: true,
    },
    reaction: {
      required: true,
    },
  },

  computed: {
    circleSize() {
      const { clientWidth } = document.body;

      return clientWidth > 400 ? '24px' : '18px';
    },
  },
  methods: {
    getColor(index) {
      if (index % 2) {
        return '#3F5798';
      }

      return '#B5D9EA';
    },

    getPercent(value) {
      return isNaN(value) ? 0 : parseFloat(value).toFixed(1);
    },
  },
};
